// 获取现有成员
import { findGroupRoleByGroupId } from "./jobChangeApi";
import { ElMessage, ElMessageBox } from "element-plus";
import { dealDate, dealDateHMS } from "../../class-manage/classMethods";

export const getUserLists = (data) => {
  data.loading = true;
  let param = {
    // groupId: JSON.parse(sessionStorage.getItem('classInfo')).groupId,
    groupId: JSON.parse(sessionStorage.getItem("groupId")),
    pageSize: data.pageSize,
    page: data.currentPage,
  };
  findGroupRoleByGroupId(param).then((res) => {
    if (res.data.code === "200") {
      let newRes = res.data.data;
      data.peopleData = newRes.pageInfo.list;
      data.total = newRes.pageInfo.total;
      for (let i in data.peopleData)
        data.peopleData[i].name = data.peopleData[i].sysUser.name;
      dealDateHMS(data.peopleData, ["updateTime"]);

      setTimeout(() => {
        data.loading = false;
      }, 300);
    }
  });
};

// 切换条数
export const sizeChanges = (val, data) => {
  data.pageSize = val;
  getUserLists(data);
};

// 切换页数
export const currentChanges = (val, data) => {
  data.currentPage = val;
  getUserLists(data);
};
