import axios from '@/axios/globalaxios'

export const findGroupRoleByGroupId = data =>{
    return axios({
        url: "/tradelab/courseGroup/findGroupRoleByGroupId",
        method: 'post',
        data:data
    })
}

